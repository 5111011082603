import { Role, User } from "@prisma/client";
import { Form, Link } from "@remix-run/react";
import { ChevronDown } from "lucide-react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import logo from "app/assets/images/AmandaLogo.png";
import { Badge } from "../ui/badge";

interface props {
  children?: React.ReactNode;
  user: Omit<User, "createdAt" | "updatedAt"> & {
    createdAt: string;
    updatedAt: string;
    UserRole: { role: Role }[];

  };
}

const Header = ({ children, user, }: props) => {
  const username = user?.email.split("@")[0];
  return (
    <header className="h-24 w-full z-50 flex grow-0 items-center justify-between bg-black p-4 text-white relative">
      <div className="relative inset-0 left-3 top-4 z-20 h-12">
        <Link to="/login" viewTransition>
          <img
            src={logo}
            className="w-36"
            alt="Amanda AI logotype white capital letters"
          />
        </Link>
      </div>
      <div className="z-50 mr-2 flex items-center space-x-3 relative">
        <div className="z-50 relative">
          {children}
        </div>
        {/* <ModeToggle /> */}
        <DropdownMenu>
          <DropdownMenuTrigger>
            <div className="flex text-sm font-light">
              {username}
              <ChevronDown className="ml-0.5 h-5 w-5" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="mr-3 min-w-[220px] rounded-none border-none bg-background"
            align="end"
          >
            <DropdownMenuGroup>
              {/* <DropdownMenuLabel>
                <Link to="/account">My Account</Link>
              </DropdownMenuLabel> */}
              <DropdownMenuItem className="flex">
                <div className="flex w-full flex-col items-stretch">
                  <Form action="/logout" method="post">
                    <button className="w-full border-t border-opacity-70 py-2 text-left font-light last-of-type:border-b hover:underline">
                      Sign Out
                    </button>
                  </Form>
                </div>
              </DropdownMenuItem>
            </DropdownMenuGroup>
            <DropdownMenuGroup>
              <div className="flex gap-1 p-1">
                {user.UserRole.map(({ role }: { role: Role }) => (
                  <Badge key={role} className="border-none">
                    {role}
                  </Badge>
                ))}
              </div>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </header>
  );
};

export default Header;
